import { connectStats } from "react-instantsearch-dom";

const Stats = ({ nbHits }: any) => {
  // console.log("Stats", nbHits);
  return <span aria-label="hidden">({nbHits} Items)</span>;
};

const CustomStats = connectStats(Stats);

export default CustomStats;
