import React, { Component, MouseEvent } from 'react';
import '@algolia/autocomplete-theme-classic';

interface ShopHqCategoryProps {
  attribute: string;
}

interface ShopHqCategoryChildProps {
  attribute: string;
  children: any;
  collapse: boolean;
  onClick: Function;
}

interface ShopHqCategoryState {
  collapse: any;
}

class Child extends Component<ShopHqCategoryChildProps, ShopHqCategoryState> {
  constructor(props: any) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick(this.setState(prevState => ({ collapse: !prevState.collapse })));
  }

  render() {
    let { attribute, children } = this.props;
    let attributeName = attribute.replace(/\s/g, "");

    return (
      <>
        <div id={attributeName} className="panel-heading header" data-toggle="collapse" data-target={".cl-" + attributeName} aria-controls={`ctl-${attributeName}`} role="button" aria-expanded="false" onClick={(event: MouseEvent<HTMLSpanElement>) => {this.props.onClick(event)}}>
          <span className={`floatLeft panel-${(this.props.collapse ? "close" : "expand")}`} data-grouporder={attributeName}></span>
          <span className="header-title">{attribute}</span>
        </div>
        <div id={"ctl-" + attributeName} className={`panel-collapse cl-${attributeName} collapse`} aria-labelledby={attributeName} aria-expanded={!this.props.collapse}>
          <div className="panel-body">
            {children}
          </div>
        </div>
      </>
    )
  }
}

export default class ShopHqCategory extends Component<ShopHqCategoryProps, ShopHqCategoryState> {
  constructor(props: ShopHqCategoryProps) {
    super(props);

    this.state = {
      collapse: true
    };
    this.toggleState = this.toggleState.bind(this);
  }

  componentDidMount(): void {
    let {attribute} = this.props;
    let attributeName = attribute.replace(/\s/g, "");

    switch (attributeName) {
      case "Department":
        this.setState({collapse: false});
        if (!document.querySelector(`#ctl-${attributeName}`)?.classList.contains("in")) {
          document.querySelector(`#ctl-${attributeName}`)?.classList.add("in");
        }
        break;
      default:
        break;
    }
  }

  toggleState(event: any) {
    this.setState(prevState => ({collapse: !prevState.collapse}));
  }

  render() {
    let {attribute, children} = this.props;

    return (
        <div className="panel-default search-refinement-group">
            <Child attribute={attribute} children={children} collapse={this.state.collapse} onClick={(event: MouseEvent<HTMLSpanElement>) => {this.toggleState(event)}}></Child>
        </div>
      );
  }
}