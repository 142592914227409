import { connectHits } from 'react-instantsearch-dom';
import ShopHqHit from './ShopHqHit';
import aa from "search-insights";
const Hits = (props: any) => { 
  const { hits, insights }=props;
  // aa('viewedObjectIDs', {   
  //   index: (window as any).config.allIndex,
  //   eventName: "PLP: Products Viewed",
  //   userToken: 'User',            
  //   objectIDs:hits.map((hit:any)=>hit.OfferCode).slice(1,20),
  // });
  return <div className='row'>
    {/* {console.log("Hits", hits)} */}
    {hits.map((hit: any, index: any) => (
      <ShopHqHit key={`ht${index}`} hit={hit} index={index} />
    ))}
  </div>
};

const ShopHqConnectedHits = connectHits(Hits);

export default ShopHqConnectedHits;