import { connectBreadcrumb } from "react-instantsearch-dom";
import CustomStats from "./ShopHqCustomStats";
import CustomBreadcrumbRefinements from "./ShopHqBreadcrumRefinements";
import QueryString from "qs";
import React, { useState, useEffect } from 'react';
let search = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });

const Breadcrumb = (Props: any) => {   
  const { items, hits, refine,canRefine, createURL,currentCategory  }=Props; 
  const [category, setCategory] = useState(()=>{
    let str=(currentCategory?.split('>')[0] ||'');
    return str.charAt(0).toUpperCase() + str.slice(1);
  }); 
 
  return (
    items.length > 0 || search.q ? <div className="row">
      <div className="col-xs-12">
        <ul className="breadcrumb">
          <li>
            <a href="/" className="breadCrumbWhite" aria-label="Shop HQ home page" >
              <i className="fa fa-home" ></i>
            </a>
          </li>
          {search.q && <li className="BreadCrumbNonLink"> {items.length > 0 ?
            <a className="breadCrumbWhite" href={createURL()} onClick={event => { event.preventDefault(); refine(); }} > Search Results "{search.q}"</a> : "Search Results\"" + search.q + "\""}
           
          </li>}
          {items.map((item: any, index: any) => (
            <li
              key={`saasdda${index}`}
              className={
                items.length === 1 || index + 1 === item.length
                  ? "BreadCrumbNonLink"
                  : "breadCrumbWhite"
              }
            >
              <a
                href={createURL(item.value)}
                onClick={(event) => {
                  event.preventDefault();
                  refine(item.value);
                }}
                style={{fontSize: "16px", color: "#1e1e1f"}}
                aria-label={item.label}
              >
                {item.label + " "}
              </a>
            </li>
          ))}
          {/* <CustomBreadcrumbRefinements
            transformItems={(items) =>
              items.filter((item: any) => item.attribute !== "Categories.lvl0")?.filter((v:  any ,i: any,a: any[])=>a.findIndex(v2=>(v?.attribute === v2?.attribute))===i)
            }
          /> */}
          {search.q && <li>Search</li>}
          {" "}
          <li><CustomStats /></li>
          
        </ul>
      </div>
    </div> : category!=''?<div className="row">
      <div className="col-xs-12">
        <ul className="breadcrumb">
          <li>
            <a href="/" className="breadCrumbWhite" aria-label="Shop HQ home page" >
              <i className="fa fa-home" ></i>
            </a>
          </li>
          {search.q && <li className="BreadCrumbNonLink"> {items.length > 0 ?
            <a className="breadCrumbWhite" href={createURL()} onClick={event => { event.preventDefault(); refine(); }} > Search Results "{search.q}"</a> : "Search Results\"" + search.q + "\""}
           
          </li>}
          
          <li className="breadCrumbWhite">
            <a href={createURL(category)} onClick={(event) => {
                  event.preventDefault();
                  refine(category);
                }}  aria-label={category} className='aa-BreadCrumbLink' >
             {category}
            </a>
          </li>&nbsp;
          <li> <CustomStats /></li>        
        </ul>
      </div>
    </div>:<></>
  );
};

const CustomBreadcrumb = connectBreadcrumb(Breadcrumb);

export default CustomBreadcrumb;
