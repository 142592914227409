import { autocomplete } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';

export function Autocomplete(props: any) {
  const containerRef = useRef(null);
  
  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    
    const search = autocomplete({
      container: containerRef.current,
      placeholder: "Search",
      onSubmit({ state }) {
        // console.log("OnSubmit Autocomplete", state)
        let items: any[] = state.collections.filter(function(element) {
          return element.source.sourceId === 'products';
        })[0]?.items;

        if(state.query !== ""){
          let url = window.location.origin;
          if(items?.length === 1){
            let productUrl = items[0].ProductUrl;
            window.open(productUrl,"_self")
          }else 
            window.open(url + "/search/?q=" + state.query,"_self")
        }
      },
      renderer: { createElement, Fragment },
      render({ elements,children,state }: any, root) {
        const {
          querySuggestionsPlugin:querySuggestions,
          products,
          productsCategories,
           todayTopValuesPlugin:todayTopValues
        }=elements
        const sourceIdsToExclude = ['todayTopValuesPlugin'];
        /* Finding hasResults if there is any result in the  querySuggestions,products,productsCategories*/
        const hasResults =
          state.collections
            .filter(
              ({ source }:any) => sourceIdsToExclude.indexOf(source?.sourceId) === -1
            )
            .reduce((prev:any, curr:any) => prev + curr.items.length, 0) > 0;
            /* Displaying TodayTopVAlues if there is no results related to our query*/
        render(hasResults ? <> 
        {querySuggestions}
        {products}
        {productsCategories}
        </> : todayTopValues, root);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}

