import React, { Component } from 'react';
import '@algolia/autocomplete-theme-classic';

interface ShopHqHitAutocompleteResultsProps {
    hit: any;
    components: any;
}

export default class ShopHqHitAutocompleteResults extends Component<ShopHqHitAutocompleteResultsProps> {
  render() {
    // const { hit, components ,children } = this.props;
    const { hit, components } = this.props;
    return (
        <a href={hit.ProductUrl} className="aa-ItemLink">
          <div className="aa-ItemContent">
            <div className="aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop">
              <img src={`//images.shophq.com/is/image/ShopHQ/${hit.OfferCode}?DefaultImage=1&amp;$40x40_jpg$`} alt={hit.Title} role="image" />
            </div>
            <div className="aa-ItemContentBody">
              <div className="aa-ItemContentTitle">
                <components.Snippet hit={hit} attribute="Title" />
              </div>
              { hit.PriceHandle &&
                <div className="aa-ItemContentDescription" style={{ color: "#000" }}>
                  <strong>{hit.PriceHandle}</strong>  
                </div>
              }
              <div className="aa-ItemContentDescription" style={{ color: "#000" }}>
                <strong>{hit.SkuPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD', })}</strong>
              </div>
            </div>
          </div>
        </a>
      );
  }
}