import { connectSortBy } from "react-instantsearch-dom";

const SortBy = ({ items, currentRefinement, refine }: any) => (
  <div className="col-xs-6 col-sm-6 visible-xs visible-sm">
    <div className="dropdown">
      <button
        type="button"
        id="sort-by-menu"
        className="btn btn-default sort-filter"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        Sort
        <i className="fa fa-long-arrow-down sort-filter-icon"></i>
        <i className="fa fa-long-arrow-up sort-filter-icon"></i>
      </button>
      <ul className="dropdown-menu" aria-labelledby="sort-by-menu">
        {items.map((item: any, index: any) => {
          return <li key={`sbm${index}`} value={item.value}><a href="#" onClick={(event) => { event.preventDefault(); refine(item.value); return false; }}>{item.label}</a></li>;
        })}
      </ul>
    </div>
  </div>
);

const ShopHqSortByMobile = connectSortBy(SortBy);
export default ShopHqSortByMobile;
