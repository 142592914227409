import { connectPagination } from 'react-instantsearch-dom';

const Pagination = ({ currentRefinement, nbPages, refine, createURL }: any) => {
    // console.log('CREATEURL', createURL());
    // let url = createURL();
    let url = '#';
    //let element = document.getElementById("MainContent");
    // console.log("Pagination", element, (element as HTMLDivElement).offsetTop, window.pageYOffset)
   // let offsetop = window.scrollY === (element as HTMLDivElement).offsetTop ? (element as HTMLDivElement).offsetTop : window.pageYOffset;
    const getOffset=()=>{
        let element = document.getElementById("product-browse-results-container");       
            return window.scrollY === (element as HTMLDivElement).offsetTop ? (element as HTMLDivElement).offsetTop : window.pageYOffset;
    }
    return nbPages > 1 ?
    <ul className="search-pagination"> 
        <li>
            <a className="search-pagination-prev" title="Previous Page" aria-label="Previous Page" href={currentRefinement !== 1 ? createURL(currentRefinement - 1) : url} onClick={(event) => {
                event.preventDefault();
                if(currentRefinement !== 1)
                    refine(currentRefinement - 1 );
                window.scrollTo(0, getOffset());}} >
                <i className="fa fa-chevron-left" aria-hidden="true" style={currentRefinement === 1 ? {color: "#adadad"} : {} }></i>
            </a>
        </li>
        <li className={(currentRefinement === 1) ? "active" : ""}>
            <a
                href={createURL(nbPages - (nbPages -1))}
                onClick={event => {
                    event.preventDefault();
                    refine(nbPages - (nbPages -1));
                    window.scrollTo(0, getOffset());
            }}>
            { nbPages - (nbPages -1) }
            </a>
        </li>
        {currentRefinement > 3 && <li><span>...</span></li>}
        {currentRefinement === nbPages && nbPages > 3 && (
            <li className={(currentRefinement -2 === currentRefinement ) ? "active" : ""}>
                <a
                    href={createURL(currentRefinement - 2)}
                    onClick={event => {
                        event.preventDefault();
                        refine(currentRefinement - 2);
                        window.scrollTo(0, getOffset());
                }}>
                { currentRefinement -2 }
                </a>
            </li>
        )}
        {currentRefinement > 2 && (
            <li className={(currentRefinement -1 === currentRefinement) ? "active" : ""}>
                <a
                    href={createURL(currentRefinement - 1)}
                    onClick={event => {
                        event.preventDefault();
                        refine(currentRefinement - 1);
                        window.scrollTo(0, getOffset());
                }}>
                { currentRefinement -1 }
                </a>
            </li>
        )}
        {currentRefinement !== 1 && currentRefinement !== nbPages && (
            <li className={currentRefinement ? "active" : ""}>
                <a
                    href={createURL(currentRefinement)}
                    onClick={event => {
                        event.preventDefault();
                        refine(currentRefinement);
                        window.scrollTo(0, getOffset());
                }}>
                { currentRefinement }
                </a>
            </li>
        )}
        {currentRefinement < nbPages - 1 && (
            <li className={(currentRefinement + 1 === currentRefinement) ? "active" : ""}>
                <a
                    href={createURL(currentRefinement + 1)}
                    onClick={event => {
                        event.preventDefault();
                        refine(currentRefinement + 1);
                        window.scrollTo(0, getOffset());
                }}>
                { currentRefinement + 1 }
                </a>
            </li>
        )}
        {currentRefinement === 1 && nbPages > 3 && (
           <li className={(currentRefinement + 2 === currentRefinement) ? "active" : ""}>
                <a
                    href={createURL(currentRefinement + 2)}
                    onClick={event => {
                        event.preventDefault();
                        refine(currentRefinement + 2);
                        window.scrollTo(0, getOffset());
                    }}>
                    { currentRefinement + 2 }
                </a>
            </li>
        )}
        {currentRefinement < nbPages - 2 && <li><span>...</span></li>}
        <li className={nbPages === currentRefinement ? "active" : ""}>
            <a
                href={createURL(nbPages)}
                onClick={event => {
                    event.preventDefault();
                    refine(nbPages);
                    window.scrollTo(0, getOffset());
                }}>
                { nbPages }
            </a>
        </li>
        <li>
            <a className="search-pagination-next" title="Next Page" aria-label="Next Page" href={currentRefinement !== nbPages ? createURL(currentRefinement + 1) : url} onClick={(event) => {
                event.preventDefault();
                if(currentRefinement !== nbPages)
                    refine(currentRefinement + 1 );
                window.scrollTo(0, getOffset());}}>
                <i className="fa fa-chevron-right" aria-hidden="true" style={currentRefinement === nbPages ? {color: "#adadad"} : {} }></i>
            </a>
        </li>
    </ul> : <></> 
}

const CustomPagination = connectPagination(Pagination);
export default CustomPagination;