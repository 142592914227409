import { connectHierarchicalMenu } from "react-instantsearch-dom";
import { changeURLNamePage, numberWithCommas } from '../../service/ShophqService';
import aa from "search-insights";

const pathname = window.location.pathname;
let element = document.getElementById("product-browse-results-container");
let offsetop = element ? element.offsetTop : 0;

const HierarchicalMenu = ({ items, refine }: any) => {
  let array = pathname.split('/');
  let filter = "";
  if(/\/c\//i.test(pathname) || /\/b\//i.test(pathname)){
      filter = changeURLNamePage(JSON.stringify(array[2]));
      filter = filter !== JSON.stringify("clearance") ? filter : "";
  }
  return ( 
  <ul>
    {items.map((item: any) => (
      (filter === "" || (item.value === undefined || item.value?.toLocaleLowerCase().includes(JSON.parse(filter)))) &&
      <li
        key={item.label}
        style={{ listStyleType: "none"}}
      >
        <label className="searchRefBox" style={item.value?.toLocaleLowerCase().includes(" > ") ? {} : {paddingLeft: "25px"}}>
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              aa('viewedFilters', {
                index: (window as any).config.allIndex,
                eventName: "PLP: Filter Viewed",
                filters: [`category:${item.value}`],
              });
              refine(item.value);
              window.scrollTo(0, offsetop);
            }}
          >
            <span className="searchRefName" style={{ fontWeight: item.isRefined ? 600 : 'normal' }}>{item.label}</span>
            <span className="productCount" id={item.label} style={{ fontWeight: item.isRefined ? 600 : 'normal' }}>
              ({numberWithCommas(item.count)})
            </span>
          </a>
        </label>
        {item.items && (
          <HierarchicalMenu items={item.items} refine={refine} />
        )}
      </li>
    ))}
  </ul>)
}

const ShophqDepartment = connectHierarchicalMenu(HierarchicalMenu);

export default ShophqDepartment;
