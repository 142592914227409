import React, { Component, MouseEvent } from "react";
import "@algolia/autocomplete-theme-classic";
import CustomPagination from "./ShopHqCustomPagination";
import ShopHqSortBy from "./ShopHqSortBy";

interface ShopHqPaginationNavbarProps {location?:string}

export default class ShopHqPaginationNavbar extends Component<ShopHqPaginationNavbarProps> {
  render() {   
    return (
        <div className="col-xs-12 mt-xs-10 mt-sm-20 mt-md-20 mt-lg-20 ">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-xs-12 mt-2 mb-2 search-pagination-section">
                <div className="serach-navbar">
                  <CustomPagination />
                </div>
              </div>
            </div>
          </div>
          
          { (window as any).config.items && (window as any).config.items.length &&
            <ShopHqSortBy
              defaultRefinement={(window as any).config.allIndex}
              items={(window as any).config.items}
              location={this.props.location}
            />
          }
        </div>
    );
  }
}
