import { capitalize } from "instantsearch.js/es/lib/utils";
import React, { Component } from "react";
import { brandsByCategory, changePageNameURL } from '../../service/ShophqService';

interface ShopHqAllBrandsByCategoryProps {
    category: string;
}

interface ShopHqAllBrandsByCategoryState {
    num: any[][] | any[];
    a: string[] | null;
    b: string[] | null;
    c: string[] | null;
    d: string[] | null;
    e: string[] | null;
    f: string[] | null;
    g: string[] | null;
    h: string[] | null;
    i: string[] | null;
    j: string[] | null;
    k: string[] | null;
    l: string[] | null;
    m: string[] | null;
    n: string[] | null;
    o: string[] | null;
    p: string[] | null;
    q: string[] | null;
    r: string[] | null;
    s: string[] | null;
    t: string[] | null;
    u: string[] | null;
    v: string[] | null;
    w: string[] | null;
    x: string[] | null;
    y: string[] | null;
    z: string[] | null;
}

const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export default class ShopHqAllBrandsByCategory extends Component<ShopHqAllBrandsByCategoryProps, any> {
    constructor(props: ShopHqAllBrandsByCategoryProps) {
        super(props);
        this.state = {
            num: [],
            a: null,
            b: null,
            c: null,
            d: null,
            e: null,
            f: null,
            g: null,
            h: null,
            i: null,
            j: null,
            k: null,
            l: null,
            m: null,
            n: null,
            o: null,
            p: null,
            q: null,
            r: null,
            s: null,
            t: null,
            u: null,
            v: null,
            w: null,
            x: null,
            y: null,
            z: null,
        }
        digits.forEach((num: string) => {
            brandsByCategory(this.props.category, num).then((results) => {
                this.setState((prevState: any) => {
                    prevState.num[+num] = results.facetHits.filter(({ value }) => {
                        return value.startsWith(num);
                    })
                    return prevState;
                });
            });
        }, () => console.log(this.state));
        alphabet.forEach((letter: string) => {
            brandsByCategory(this.props.category, letter).then((results) => {
                this.setState((prevState: any) => {
                    prevState[letter] = results.facetHits.filter(({ value }) => {
                        return value.startsWith(letter) || value.startsWith(letter.toUpperCase());
                    })
                    return prevState;
                });
            });
        }, () => console.log(this.state));
    }
    render() {
        return (
            <>
                <div className="row">
    <div className="col-xs-12">
        <div className="brands-category-header"><h3>{capitalize(this.props.category)} { this.props.category ? ' : ' : '' } All Brands</h3></div>
        <div id="brand-alphabet-index">
            {
                digits.map((num: string) => {
                    if(this.state.num[+num] && this.state.num[+num].length > 0) {
                        return <span className="brand-alphabet-active"><a href={`#${num}`}>{ num }</a>&nbsp;</span>
                    } else {
                        return <></>;
                    }
                })
            }
            {
                alphabet.map((letter: string) => {
                    if(this.state[letter] && this.state[letter].length > 0) {
                        return <span className="brand-alphabet-active"><a href={`#${letter.toUpperCase()}`}>{ letter.toUpperCase() }</a>&nbsp;</span>
                    } else {
                        return <span className="brand-alphabet-inactive">{ letter.toUpperCase() }&nbsp;</span>
                    }
                })
            }
        </div>

        <div className="brands-category-content">
        { digits.map((num: string) => {
                if(this.state.num[+num] && this.state.num[+num].length > 0) {
                    return (
                            <>
                                <div className="col-xs-12 brand-alphabet-title-header" data-toggle="collapse" data-target={`#Brands-${num}`}>
                                    <span className="brand-alphabet-title" id={num}>{num}</span>
                                    <span className="visible-xs floatRight fa fa-plus"></span>
                                </div>
                            <div className="hidden-xs brand-alphabet-list" id={`Brands-${num}`}>
                                { this.state.num[+num].map((brand: any) => {
                                    return (
                                     
                                        <div className="col-xs-12 col-sm-4 brand-name"><a href={`/b/${encodeURIComponent(changePageNameURL(this.props.category.toLowerCase()))}/${encodeURIComponent(changePageNameURL(brand.value.toLowerCase()))}`}><span> {brand.value}</span></a>
                                         
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="col-xs-12 divider"></div>
                            </>
                        );}
                    return <></>;
                    })
                    }
            { alphabet.map((letter: string) => {
                if(this.state[letter] && this.state[letter].length > 0) {
                    return (
                            <>
                                <div className="col-xs-12 brand-alphabet-title-header" data-toggle="collapse" data-target={`#Brands-${letter.toUpperCase()}`}>
                                    <span className="brand-alphabet-title" id={letter.toUpperCase()}>{letter.toUpperCase()}</span>
                                    <span className="visible-xs floatRight fa fa-plus"></span>
                                </div>
                            <div className="hidden-xs brand-alphabet-list" id={`Brands-${letter.toUpperCase()}`}>
                                { this.state[letter].map((brand: any) => {
                                    return (
                                        <div className="col-xs-12 col-sm-4 brand-name"><a href={`/b/${encodeURIComponent(changePageNameURL(this.props.category.toLowerCase()))}/${encodeURIComponent(changePageNameURL(brand.value.toLowerCase()))}`}><span> {brand.value}</span></a>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="col-xs-12 divider"></div>
                            </>
                        );}
                    return <></>;
                    })
                    }
        </div>
    </div> 
</div>
            </>
        );
    }
}