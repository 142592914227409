// import { AnyMxRecord } from "dns";
import React, { Component } from "react";
import { getThumbnailNumber } from "../../service/ShophqService";
import aa from "search-insights";

interface ShopHqHitProps {
  hit: any;
  index: any;
}
interface ShopHqHitState {
  translate: any;
  transition: any;
  offerCode: any;
}

let algoliaData = {};

export default class ShopHqHit extends Component<
  ShopHqHitProps,
  ShopHqHitState
> {
  constructor(props: ShopHqHitProps) {
    super(props);
    this.state = {
      translate: 0,
      transition: 0,
      offerCode: "",
    };
  }

  changeImage(thumbnail: any, event: any, totalNumber: any) {
    let parentNode = event.target.parentNode.parentNode;
    let children = parentNode.children;
    for (var i = 0; i < totalNumber; i++) {
      if (children[i].firstChild.classList.contains("color-selected")) {
        children[i].firstChild.classList.remove("color-selected");
        children[i].firstChild.classList.add("color");
      }
    }

    event.target.classList.remove("color");
    event.target.classList.add("color-selected");
    let number = getThumbnailNumber(thumbnail);
    this.setState({ offerCode: number });
  }

  render() {
    // const { hit, children } = this.props;
    const { hit, index: hitPosition } = this.props;
    // console.log(hit);
    let valuePay = hit.OfferPriceLow
      ? Math.round((hit.OfferPriceLow * 100) / 6) / 100
      : 0;
    // let originalPrice = hit.OfferPriceLow && hit.PercentOff ? (hit.OfferPriceLow * 100) / (100 - hit.PercentOff) : 0;
    let originalPrice = hit.ListPriceLow;
    let saveValue = hit.OfferPriceLow ? originalPrice - hit.OfferPriceLow : 0;
    let arrayThumbnails = hit.Thumbnails?.filter((img: any) => {
      return img !== " ";
    });
    const imageUrl = (window as any).config.imageUrl;

    aa("getUserToken", {}, (err, userToken) => {
      if (err) {
        console.error(err);
        return;
      }
    });
    // (window as any).aa = aa;

    return (
      <div
        key={`hit${hit.OfferCode}`}
        id={hit.OfferCode}
        className="col-xs-6 col-sm-6 col-md-4 search-product-detail-container"
      >
        <div className="quickview-hovereffect">
          <a
            id={`${hit.OfferCode}-pdp-link1`}
            href={hit.ProductUrl ? hit.ProductUrl : 0}
            onClick={() => {
              aa("clickedObjectIDsAfterSearch", {
                eventName: "PLP: Quickview Hover",
                index: (window as any).config.allIndex,
                queryID: hit.__queryID,
                objectIDs: [hit.objectID],
                positions: [hitPosition + 1],
              });
              algoliaData = {
                data: {
                  index: (window as any).config.allIndex,
                  queryID: hit.__queryID,
                  objectIDs: [hit.objectID],
                  // positions: [hitPosition + 1],
                },
              };
              (window as any).localStorage.setItem(
                "algoliaData",
                JSON.stringify(algoliaData)
              );
            }}
            target="_self"
          >
            <div
              className={`line-class-overlay ${hit.PriceHandle?.replace(
                /\s+/g,
                "-"
              )
                .replace(/'/g, "")
                .toLowerCase()}`}
            ></div>

            <img
              id={`${hit.OfferCode}-prod-img`}
              alt={`Image of product ${hit.OfferCode}`}
              className="lazy img-responsive"
              data-original={`${imageUrl}${
                this.state.offerCode === ""
                  ? hit.OfferCode
                  : hit.OfferCode + "_" + this.state.offerCode
              }?DefaultImage=1&$472x472_jpg$`}
              src={`${imageUrl}${
                this.state.offerCode === ""
                  ? hit.OfferCode
                  : hit.OfferCode + "_" + this.state.offerCode
              }?DefaultImage=1&$472x472_jpg$`}
              role="image"
            />
          </a>
          <div className="quickview-overlay" id={`btnQV-${hit.OfferCode}`}>
            <a
              id={`${hit.OfferCode}-qv-link`}
              className="addQuickViewLink"
              data-toggle="modal"
              data-offercode={hit.OfferCode}
              data-colorcode="Default"
              href="#shophq"
              // onClick={()=>{
              // aa('clickedObjectIDsAfterSearch', {
              //   index: (window as any).config.allIndex,
              //   eventName: "PLP: Quickview Overlay",
              //   queryID: hit.__queryID,
              //   objectIDs: [hit.objectID],
              //   positions: [hit.position],
              // });
              // }}
            >
              QUICKVIEW
            </a>
          </div>
        </div>
        <div
          data-bv-show="inline_rating"
          data-bv-product-id={hit.OfferCode}
          data-bv-redirect-url={hit.ProductUrl}
        ></div>
        <div className="row sku-row">
          <div
            id={`${hit.OfferCode}-color-carousel-prev`}
            style={
              arrayThumbnails?.length > 4
                ? { display: "block" }
                : { display: "none" }
            }
            className="col-xs-1 image-carousel-controls prev carousel-disable"
            onClick={() => {
              this.state.translate < 0 &&
                this.setState({ translate: this.state.translate + 55 });
            }}
          >
            <i className="fa fa-angle-left fa-stack-2x"></i>
          </div>
          <div className="col-xs-9 col-sm-10 image-carousel">
            <div
              id={`${hit.OfferCode}-color-carousel`}
              className="owl-carousel owl-loaded"
            >
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform:
                      "translate3d(" + this.state.translate + "px, 0px, 0px)",
                    transition: "all " + this.state.transition + "s ease 0s",
                    width:
                      arrayThumbnails?.length === 1
                        ? "55px"
                        : arrayThumbnails?.length * 54.75 + "px",
                  }}
                >
                  {arrayThumbnails?.length > 0 ? (
                    arrayThumbnails?.map((thumbnail: any, index: any) => (
                      <div
                        key={`sdja${index}`}
                        className="owl-item active"
                        style={{ width: "49.684px", marginRight: "5px" }}
                        onClick={(event) =>
                          this.changeImage(
                            thumbnail,
                            event,
                            arrayThumbnails?.length
                          )
                        }
                      >
                        <img
                          data-original={`//${thumbnail}`}
                          src={`//${thumbnail}`}
                          title={hit.Title}
                          alt={hit.Title}
                          className={`lazy img-responsive sku-img ${
                            index === 0 ? "color-selected" : "color"
                          }`}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      className="owl-item active"
                      style={{ width: "49.684px", marginRight: "5px" }}
                    >
                      <img
                        data-original={`//images.shophq.com/is/image/ShopHQ/${hit.OfferCode}?DefaultImage=1&$40x40_jpg$`}
                        src={`//images.shophq.com/is/image/ShopHQ/${hit.OfferCode}?DefaultImage=1&$40x40_jpg$`}
                        title={hit.Title}
                        alt={hit.Title}
                        className="lazy img-responsive sku-img color-selected"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="owl-nav disabled">
                <div className="owl-prev">prev</div>
                <div className="owl-next">next</div>
              </div>
              <div className="owl-dots disabled"></div>
            </div>
          </div>
          <div
            id={`${hit.OfferCode}-color-carousel-prev`}
            style={
              arrayThumbnails?.length > 4
                ? { display: "block" }
                : { display: "none" }
            }
            className="col-xs-1 image-carousel-controls next"
            onClick={() => {
              let movement = (arrayThumbnails?.length - 4) * -55;
              movement !== this.state.translate &&
                this.setState({ translate: this.state.translate - 55 });
              this.state.transition === 0 && this.setState({ transition: 0.2 });
            }}
          >
            <i className="fa fa-angle-right fa-stack-2x"></i>
          </div>
        </div>{" "}
        {/* {hit.Stock <= 0 && <div className="offer-availability offer-availability-sold-out"><div></div></div>} */}
        {/* {hit.Stock > 0 && hit.Stock <= 10 && <div className="offer-availability offer-availability-amlost-gone"><div></div></div>}
        {hit.Stock > 10 && <div className="offer-availability"><div></div></div>} */}
        <div className="search-product-detail">
          <div className="BrowseProducts-caption">
            <a
              id={`${hit.OfferCode}-pdp-link2`}
              className="blue-hover ItemDescription-3-Line"
              href={hit.ProductUrl ? hit.ProductUrl : 0}
              onClick={() => {
                aa("clickedObjectIDsAfterSearch", {
                  eventName: "PLP: Product Clicked",
                  index: (window as any).config.allIndex,
                  queryID: hit.__queryID,
                  objectIDs: [hit.objectID],
                  positions: [hitPosition + 1],
                });
                algoliaData = {
                  queryID: hit.__queryID,
                  objectIDs: [hit.objectID],
                  // positions: [hitPosition + 1],
                };
                (window as any).localStorage.setItem(
                  "algoliaData",
                  JSON.stringify(algoliaData)
                );
              }}
              target="_self"
              dangerouslySetInnerHTML={{ __html: hit.Title }}
            ></a>
          </div>
          <div className="sale-price-section">
            <span className="sale-price-label">ShopHQ Price </span>{" "}
            <span className="sale-price">
              {hit.OfferPriceLow?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}{" "}
              {hit.OfferPriceLow === hit.OfferPriceHigh
                ? ""
                : ` - ${hit.OfferPriceHigh.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </span>
          </div>
          {allowedPriceHandles.includes(hit.PriceHandle) &&
            hit.OfferPriceLow !== originalPrice &&
            originalPrice > 0 &&
            hit.PercentOff > 0 && (
              <>
                <div className="list-price-section">
                  <span className="list-price-label">ShopHQ Price: </span>{" "}
                  <span className="list-price">
                    {originalPrice.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}{" "}
                    {originalPrice === hit.ListPriceHigh
                      ? ""
                      : ` - ${hit.ListPriceHigh.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}`}
                  </span>
                </div>
                <div className="price-saved-section">
                  <span className="dollar-saved">
                    Save:{" "}
                    {saveValue.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>{" "}
                  <span className="percent-saved">
                    ({Math.round(hit.PercentOff)}%){" "}
                  </span>
                </div>
              </>
            )}
          {(hit.Refinements as string[]).find((val: string) => {
            return val === "6 ValuePay";
          }) && (
            <div className="shophq-green">
              <span>
                or 6 ValuePay :{" "}
                {valuePay.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
          )}
          <div id={`BVRRInlineRating-${hit.OfferCode}`}></div>
        </div>
      </div>
    );
  }
}

const allowedPriceHandles = [
  `Today's Top Deal`,
  `Clearance Price`,
  `Flash Price`,
  `Must Have Buy`,
  `New Low Price`,
  `This Show Only`,
  `This Day Only`,
  `This Event Only`,
  `Once Only`,
  `This Weekend Only`,
  `Today Only Price`
];
