import React from 'react';
import { connectRange } from 'react-instantsearch-dom';

// Prerequisite: install rheostat@4
import 'rheostat/initialize';
import Rheostat from 'rheostat';
import 'rheostat/css/rheostat.css';
import '../../CustomSlider.css'
import ShopHqCategory from './ShopHqCategory';
import aa from "search-insights";


const setCustomranges=({min,max}:any)=>{ 
   if(max===undefined) return [];  
  let range=[{label:"Min",value:min}];
  let digitCount=max.toString().length-1;
  let maxRange=parseInt( (`1${Array(digitCount).fill(0).join('')}`).replace(',',''));
  let maxValue=0;
  range.push({label:`${(maxRange*0.5)}`,value:maxRange*0.5})
  for(let i=maxRange;i<max;){
    maxValue=i;
    range.push({label:`${i}`,value:i});
    i+=maxRange;
   
  }
  range.push({label:`${maxValue}+`,value:max});
  return range;
}
const RangeSlider = (props:any) => {
  const { min, max, currentRefinement, canRefine,count:items, refine,label }=props;
  const [priceMin, setPriceMin] = React.useState(currentRefinement.min);
  const [priceMax, setPriceMax] = React.useState(currentRefinement.max);
  const [rangeSteps, setRangeSteps] = React.useState(setCustomranges({min,max}));
  
  React.useEffect(()=>{ 
  if(min !=undefined && max !=undefined){
    setPriceMin(min);
    setPriceMax(max);
    setRangeSteps(setCustomranges({min,max}));
      
  }
  if (canRefine) {
    setPriceMax(currentRefinement.max);
    setPriceMin(currentRefinement.min);
  }
 },[min,max]);
    React.useEffect(()=>{ 
    if (canRefine) {    
      setPriceMax(currentRefinement.max);
      setPriceMin(currentRefinement.min);
    } 
  }, [currentRefinement.min, currentRefinement.max]);

  if (min === max) {
    return null;
  }

  const onChange = ({ values: [min, max] }:any) => {   
    if (currentRefinement.min !== min || currentRefinement.max !== max) {  
      if(items.length>2)   
      {
        // aa('viewedFilters', {
        //   index: (window as any).config.allIndex,
        //   eventName: "PLP: Filter Viewed",
        //   filters: [`Price:{ ${min}, ${max} }`],
        // }); 
        refine({ min, max });
    }
       else 
       refine({min:currentRefinement.min,max:currentRefinement.max});
    }
  };
  
  const onValuesUpdated = ({ values: [min, max] }:any) => {   
  };
  const inUSD =Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }); 
 
  return (
    min !== max ?
    <><ShopHqCategory attribute={label} > 
    <Rheostat
      min={min}
      max={max}
      snap={true}
      snapPoints={rangeSteps.map((item:any)=>item.value)}
      values={[currentRefinement.min, currentRefinement.max]}      
      onChange={onChange}
      onValuesUpdated={onValuesUpdated}
    >
      <div 
    style={{margin:"10px 0px"}}
     className="rheostat-values">
      <div
        className="rheostat-marker rheostat-marker--large"
        style={{ position:"absolute" }}
      >
        <div className="rheostat-value">{inUSD.format(priceMin)}</div>
      </div>
      <div
        className="rheostat-marker rheostat-marker--large"
        style={{ right: 0 ,position:"absolute"}}
      >
        <div className="rheostat-value">{inUSD.format(priceMax)}</div>
      </div>
      </div>
    </Rheostat>
          
    </ShopHqCategory>
    <hr className="line"></hr>
      </>
   :<>No result</>
  );
};

const ShopHqCustomRangeSlider = connectRange(RangeSlider);
export default ShopHqCustomRangeSlider;