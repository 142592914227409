import algoliasearch from 'algoliasearch';
import { capitalize } from 'instantsearch.js/es/lib/utils';

const ALGOLIA_APP_ID='TUTM6TNAMV';
const ALGOLIA_API_KEY='ffe92be1e11852252b6cce457162edc1'

const searchClient = algoliasearch(ALGOLIA_APP_ID,ALGOLIA_API_KEY)
   


const searchIndex = searchClient.initIndex((window as any).config.allIndex);

const brandsByCategory = (category: string, brandQuery: string) => {
    // console.log('Category', category);
    // category = '\'Health & Fitness';
    return searchIndex.searchForFacetValues('Brand', brandQuery, {
        filters: category ? `Categories.lvl0:'${category}'` : '',
        maxFacetHits: 100,
        sortFacetValuesBy: 'alpha'
    })
}

const searchQuery = (query: string, filters: string) => {    
    return searchIndex.search(query, {
        filters: filters,
        // facetFilters: facetFilters
    })
}

const changeURLNamePage = (name: any) => {    
    return name.replaceAll('--and--', ' & ').replaceAll('---', ' / ').replaceAll('-', ' ').replaceAll('_', '-');
}

const changePageNameURL = (name: any) => {    
    return name.replaceAll('-', '_').replaceAll(' & ', '--and--').replaceAll(' / ', '---').replaceAll(' ', '-');
}

const capitalizeFirstLetter = (string: any) => { 
    let array = string.split(' ');
    let newArray: any[] = [];
    if(array.length > 1){
        array.forEach((element: any) => {
            element = element !== "&" || element !== "/" || element !== "(" || element !== ")" ? capitalize(element) : element;
            newArray.push(element);
        });
        return array = newArray.join(' ');
    }else{
        return capitalize(string);
    }
}

const getByRuleContext = (ruleContext: string) => {
    return searchIndex.search('', {
        ruleContexts: [ruleContext]
    });
}

const numberWithCommas = (number: any) => {
    
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getThumbnailNumber = (thumbnail: any) => {
    let thumbnailNumber = thumbnail.substring(
        thumbnail.indexOf("_") + 1, 
        thumbnail.lastIndexOf("?")
    );
    return thumbnailNumber;
}

export { changeURLNamePage, changePageNameURL, capitalizeFirstLetter, numberWithCommas, getThumbnailNumber, searchClient, brandsByCategory, searchQuery, getByRuleContext,ALGOLIA_APP_ID,ALGOLIA_API_KEY }