import React from 'react';
import ShopHqQueryResult from './components/ResultPage/ShopHqQueryResults';
import { searchClient, searchQuery } from './service/ShophqService';
import qs from 'qs';
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-dom';
import { RefinementList } from 'react-instantsearch-dom';
import { capitalizeFirstLetter, changeURLNamePage, getByRuleContext } from './service/ShophqService';
import { QueryRuleCustomData } from 'react-instantsearch-dom';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'qs';
import ShopHqAllBrandsByCategory from './components/ResultPage/ShopHqAllBrandsByCategory';
import { resourceLimits } from 'worker_threads';
import ShopHqNoResultsFound from './components/ResultPage/ShopHqNoResultsFound';
import { serializePayload } from 'instantsearch.js/es/lib/utils';

const createURL = (state: any) => `#${qs.stringify(state)}`;
const searchStateToUrl = (location: any, searchState: any) =>
  searchState ? `${location.pathname}${location.search}${createURL(searchState)}` : '';
const urlToSearchState = (location: any) => {  
  let parsedQs = qs.parse(location.hash.slice(1));
  let search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if(search.q) {
    parsedQs.query = search.q;
  }
  return parsedQs;
};

function ProductBrowseResults() {
  getByRuleContext('test_context').then((results: any) => {
  })
  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = React.useState(
    urlToSearchState(location)
  );
  const [load, setLoad] = React.useState<boolean>(false);
  const [hasResults, setHasResults] = React.useState<boolean>(false);
  const setStateId = React.useRef() as any;
  React.useEffect(() => {
    const nextSearchState = urlToSearchState(location);
    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
  }, [location]);
  function onSearchStateChange(nextSearchState: any) {
    clearTimeout(setStateId.current);
    setStateId.current = setTimeout(() => {
      history.push(
        searchStateToUrl(location, nextSearchState),
        nextSearchState
      );
    }, 700);
    setSearchState(nextSearchState);
  }
  
  let search = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
  let pathname = window.location.pathname.split( '/' );
  let brand: string[] = [], category: string[] = [], virtual: string[] = [], boutique: string[] = [], rvalues: string[] = [];
  const url = window.location.href;
  let filter = "", defaultBrand = "", defaultCategory = "", defaultPriceHandle = "", defaultBoutique = "";
  let brandsList = false;
  let filters: string[] = [];

  switch (true)  {
    case (/\/c\//i.test(url)):
      pathname.forEach(function(name){
        if(name !== "" && name !== "c"){
          name = changeURLNamePage(name);
          category.push(name);
        } 
      });
      if(search.c) {
        category.push(changeURLNamePage(search.c as string));
      }
      defaultPriceHandle = category[0] === "clearance" ? "Clearance Price" : "";
      filter = category[0];
      filters.push(`Categories.lvl0:${category[0]}`);
      brandsList = category[1] === 'brands list';
      defaultCategory = brandsList ? category[0] : (category.join(' > ').includes("clearance")) ? "" : category.join(' > ');
      defaultBrand = "";
      break;
  
    case (/\/b\//i.test(url)):
      pathname.forEach(function(name){
        if(name !== "" && name !== "b"){
          name = changeURLNamePage(name);
          brand.push(name);
        } 
      });
      brandsList = brand[0] === 'brands list';
      defaultCategory = filter = brandsList ? '' : brand[0];
      defaultBrand = decodeURIComponent(brand[1]);
      break;
      
    case (/\/v\//i.test(url)):
      pathname.forEach(function(name){
        if(name !== "" && name !== "v"){
          virtual.push(name);
        } 
      });
      defaultPriceHandle = virtual[0] === "todays special deals" ? "Today's Top Value" : "";
      break;

    case (/\/a\//i.test(url)):
      pathname.forEach(function(name){
        if(name !== "" && name !== "a"){
          name = changeURLNamePage(name);
          boutique.push(name);
        } 
      });
      defaultBoutique = boutique[1];
      break;
    default:
      filter = "";
  }; 
  /* Pushing 'common_context' [which is used to data related to facets, common across all the algolia pages] in already existing  virtual array .  */
  virtual.push('common_context');

  if(search.r){
    let searchR = JSON.stringify(search.r);
    searchR = searchR.replace(/"/g, '');
    let values = searchR.split("+")[0].split(" ");
    values.forEach(element => {
      if(element === "clearance") {
        defaultPriceHandle = "Clearance Price";
      } else {
        rvalues.push(changeURLNamePage(capitalizeFirstLetter(element)));
      }
    });
  }

  const defaultFilterQuery=(defaultBrand ? `Brand:"${defaultBrand}" AND `: '') + (defaultCategory ? `Categories.lvl0:"${defaultCategory}" OR Categories.lvl1:"${defaultCategory}" OR Categories.lvl2:"${defaultCategory}"` : '');
  if(brandsList) {
    return (
      <ShopHqAllBrandsByCategory category={defaultCategory} />
    );
  } else {
    
  searchQuery('', defaultFilterQuery).then((result) => {
   if(result.nbHits > 0) {
      setHasResults(true);
    }
    setLoad(true);
  })
  return (
    load ? (hasResults ?
    <InstantSearch
        indexName={(window as any).config.allIndex}
        searchClient={searchClient}
        searchState={searchState}
        // onSearchStateChange={(s) => { console.log("onSearchStateChange", s); setSearchState(s);}}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
    >
      {/* Passing array(virtual) which contains context string values to the Configure component*/}
     
      <Configure clickAnalytics facetingAfterDistinct={false} ruleContexts={virtual}/>
      <QueryRuleCustomData
        transformItems={(items: any[]) => {
          const match = items.find(data => Boolean(data.redirect));
          if (match && match.redirect) {
            window.location.href = match.redirect;
          }
          return items;
        }}
      >
        {() => null}
      </QueryRuleCustomData>
      <div style={{display: 'none'}} >
        { rvalues.length && <RefinementList attribute={'Refinements'}  defaultRefinement={rvalues} /> }
        <SearchBox/>
        { defaultPriceHandle !== "" && <RefinementList attribute={'PriceHandle'}  defaultRefinement={defaultPriceHandle}/>}
        { defaultBoutique !== "" && <RefinementList attribute={'Boutique'}  defaultRefinement={defaultBoutique}/>}
        {/* { defaultBrand !== "" && <RefinementList attribute={'Brand'}  defaultRefinement={defaultBrand}/>} */}
      </div>
      <ShopHqQueryResult searchClient={searchClient} filter={filter} category={defaultCategory} defaultBrand={capitalizeFirstLetter(defaultBrand)}/>
    </InstantSearch> :
    <ShopHqNoResultsFound />) :
    <></>
    );
}
}

export default ProductBrowseResults