import React from 'react';
// import algoliasearch from 'algoliasearch';
import { searchClient  } from './service/ShophqService';
import { InstantSearch } from 'react-instantsearch-dom';
import ShopHqAutocomplete from './components/Autocomplete/ShopHqAutocomplete';

// const searchClient = algoliasearch(
//   'TUTM6TNAMV',
//   '3ec6581f16aad5207ffdb5c3a34552b4'
// );


// const config = (window as any).config;

export default function GlobalSearchHeader() {
  return (
    /* 
      All the Algolia components, connector should be wrapped by InstantSearch component.
      IndexName and Searchclient should be passed to instantsearch componet 
    */
      <InstantSearch
        indexName= {(window as any).config.allIndex}
        searchClient={searchClient}
      >
        
        <ShopHqAutocomplete searchClient={searchClient}/>
      </InstantSearch>
  );
}
