import React, { Component } from 'react';
import { getAlgoliaResults, getAlgoliaFacets} from '@algolia/autocomplete-js';
import { Autocomplete } from './Autocomplete';
import ShopHqHitAutocompleteResults from './ShpHqAutocompleteResults';
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import '@algolia/autocomplete-theme-classic';
import '../../App.css';
import { changePageNameURL, getByRuleContext } from '../../service/ShophqService';

interface ShopHqAutocompleteProps {
    searchClient: any;
}

// const algoliasearch = require('algoliasearch/lite');

export default class ShopHqAutocomplete extends Component<ShopHqAutocompleteProps>{
  render() {
    // let { searchClient, children } = this.props;
    let { searchClient } = this.props;

    return (
      <div className="app-container">
        <Autocomplete
          openOnFocus={true}
          plugins= {
            /* Returns the Popular searches based on the query value*/
            [createQuerySuggestionsPlugin({
              searchClient,
              indexName: (window as any).config.querySuggestionIndex,
              // getSearchParams({ state, item } : any) {
              //   let query = state.query.length;
              //   return { hitsPerPage: state.query && query > 2 ? 3 : 0 };
              // },
              transformSource({ source, onTapAhead }) {
                return {
                  ...source,
                  getItemUrl({ item }) {
                    return `/search/?q=${item.query}`;
                  },
                  templates: {
                    header({ items, components }: any) {                     
                      return (
                        <React.Fragment>
                          <span className="aa-SourceHeaderTitle">Trending Searches</span>
                          <div className="aa-SourceHeaderLine"/>
                        </React.Fragment>
                      );
                    },
                    ...source.templates,
                    item(params) {
                      const { item } = params;
                      return (
                        <a
                          className="aa-ItemLink"                         
                          href={`/search/?q=${item.query}`}
                        >
                          {source.templates.item(params)}
                        </a>
                      );
                    },
                  },
                };
              },
            })]
          }
          getSources={({ query }: any) => {
            // if (!query || query.length < 3) {
            //   return [];
            // }
            
            return [
            /* Returns the Products based on the query value*/
            {
              sourceId: 'products',
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: (window as any).config.allIndex,
                      query,
                      params: {
                        hitsPerPage: 3,
                        attributesToSnippet: ["name:10"],
                        snippetEllipsisText: "…"
                      }
                    },
                  ],
                });
              },
              templates: {
                header({ items, components }: any) {
                  return (
                    <React.Fragment>
                      <span className="aa-SourceHeaderTitle">Products</span>
                      <div className="aa-SourceHeaderLine"/>
                    </React.Fragment>
                  );
                },
                item({ item, components }: any) {
                  return (
                      <ShopHqHitAutocompleteResults hit={item} components={components} />
                  );
                },
                 noResults ({ item, components }: any) {
               return "NO results were found"
              }
              },
            },
            /* Returns the Categories based on the query value*/
            {
              sourceId: "productsCategories",
              getItems({ query }: any) {
                return getAlgoliaFacets({
                  searchClient,
                  queries: [
                    {
                      indexName: (window as any).config.allIndex,
                      query,
                      facet: "Categories.lvl1",
                      params: {
                        maxFacetHits: 3
                      }
                    }
                  ]
                });
              },
              templates: {
                header() {
                  return (
                    <React.Fragment>
                      <span className="aa-SourceHeaderTitle">
                        Products Categories
                      </span>
                      <div className="aa-SourceHeaderLine" />
                    </React.Fragment>
                  );
                },
                item({ item }: any) {
                  let path = item.label.replaceAll(' > ', '/');
                  path = changePageNameURL(path);

                  return <a className="aa-ItemLink" href={window.location.origin + "/c/" + path?.toLowerCase()}>{item.label}</a>;
                }
              }
            },
            /* Returns the todayTopValues products*/
            {
              sourceId: 'todayTopValuesPlugin',
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: (window as any).config.allIndex,
                      query:"",
                      params: {
                        hitsPerPage: 3,
                        attributesToSnippet: ["name:10"],
                        snippetEllipsisText: "…",
                        ruleContexts:['todays-special-deals'] // Specific context which is used to return today top values products
                        
                      }
                    },
                  ],
                });
              },
              templates: {
                header({ items, components }: any) {
                  return (
                    <React.Fragment>
                      <span className="aa-SourceHeaderTitle">Products</span>
                      <div className="aa-SourceHeaderLine"/>
                    </React.Fragment>
                  );
                },
                item({ item, components }: any) {
                  return (
                      <ShopHqHitAutocompleteResults hit={item} components={components} />
                  );
                },
                 noResults ({ item, components }: any) {
               return "NO results were found"
              }
              },
            },
            // {
            //   sourceId: "productsBrand",
            //   getItems({ query }: any) {
            //     return getAlgoliaFacets({
            //       searchClient,
            //       queries: [
            //         {
            //           indexName: 'shq_skus_stage_en',
            //           query,
            //           facet: "Brand",
            //           params: {
            //             maxFacetHits: 3
            //           }
            //         }
            //       ],
            //     });
            //   },
            //   templates: {
            //     header() {
            //       return (
            //         <React.Fragment>
            //           <span className="aa-SourceHeaderTitle">
            //             Products Brands
            //           </span>
            //           <div className="aa-SourceHeaderLine" />
            //         </React.Fragment>
            //       );
            //     },
            //     item({ item }: any) {
            //       let brand  = changePageNameURL(item.label);
            //       return <a href={window.location.origin + "/b/" + brand?.toLowerCase()}>{item.label}</a>;
            //     }
            //   }
            // }
          ]}}
        />
      </div>
      );
  }
}




