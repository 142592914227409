import { connectSortBy } from "react-instantsearch-dom";


const SortBy = ({ items, currentRefinement, refine,location }: any) => ( 
  <div className="col-sm-6 search-sort-section hidden-xs">   
    <label htmlFor={`SearchItemTab_Sort_SelectedId_${location}`} className="sort-text">Sort By: </label>&nbsp;&nbsp;&nbsp;
    <select    
      className="search-select"
      data-val="true"
      value={currentRefinement || ''}
      onChange={(event) => {
        refine(event.target.value);
        let element = document.getElementById("product-browse-results-container");       
        let offsetop = window.scrollY === (element as HTMLDivElement).offsetTop ? (element as HTMLDivElement).offsetTop : window.pageYOffset;
        window.scrollTo(0, offsetop);
        
      }}
      data-val-number="The field SelectedId must be a number."
      id={`SearchItemTab_Sort_SelectedId_${location}`}
      name="SearchItemTab.Sort.SelectedId"
    >
      {items.map((item: any, index: any) => {
        return <option key={`sbd${index}`} value={item.value}>{item.label}</option>;
      })}
    </select>
  </div>
);

const ShopHqSortBy = connectSortBy(SortBy);
export default ShopHqSortBy;
