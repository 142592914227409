import React, { Component, MouseEvent } from "react";
import "@algolia/autocomplete-theme-classic";
import ShopHqPaginationNavbar from "../PaginationSortBy/ShopHqPaginationNavbar";
import ShopHqSortByMobile from "./ShopHqSortByMobile";

// interface ShopHqPaginationNavbarProps {}

const showFiltersMobile = (event: MouseEvent<HTMLSpanElement>) => {
  event.preventDefault();
  let element = document.querySelector<HTMLElement>(
    "#search-selected-refinement"
  )!;
  let style = window.getComputedStyle(element);
  let display = style.display;

  if (display === "none") element.style.display = "block";
  else element.style.display = "none";
  // return false;
};

interface ShopHqPaginationMobileProps {}

export default class ShopHqPaginationMobile extends Component<ShopHqPaginationMobileProps> {
  render() {
    return (
      <div className="row">
        <div className="col-sm-3 hidden-xs hidden-sm filter-lable">
          <div className="filter ">Filter</div>
          <hr className="line filter-line" />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-9">
          <div className="row search-page">
            <div className="col-xs-6 col-sm-6 visible-xs visible-sm">
              <button
                type="button"
                className="btn btn-default sort-filter"
                onClick={showFiltersMobile}
              >
                Filter
                <i className="fa fa-filter sort-filter-icon fa-chevron-down"></i>
              </button>
            </div>
            { (window as any).config.items && (window as any).config.items.length &&
              <ShopHqSortByMobile
                defaultRefinement={(window as any).config.allIndex}
                items={(window as any).config.items}
              />
            }
            <ShopHqPaginationNavbar location="top" />
          </div>
          <hr className="line filter-line" style={{ marginTop: "11px" }} />
        </div>
      </div>
    );
  }
}
