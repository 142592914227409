import { connectStats } from "react-instantsearch-dom";
import ShopHqNoResultsFound from "./ShopHqNoResultsFound";

const Stats = ({ nbHits, children }: any) => {
  if(nbHits === 0 ){
    return <ShopHqNoResultsFound />
  }
  else {
    return children;
  }
};

const ShopHqQueryWrapper = connectStats(Stats);

export default ShopHqQueryWrapper;
