import { useEffect, useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { RefinementList } from 'react-instantsearch-dom'
import ShopHqCategory from './ShopHqCategory'


const CustomRefinementList = (props: any) => {
    const {attribute,items,label,defaultBrand,isSearchable,isFromSearch} = props
    const [show,setShow]=useState(false)
    useEffect(()=>{
    if (isFromSearch){
      setShow(true)
    }
    else if (items?.length > 0){setShow(true)
    }
    else {
      setShow(false)
    }
    },[isFromSearch,items])
   //props?.attribute === "GemstoneFeature" && console.log("props : ",items)
  return (
    <>
    { show ?  
     <>
     <ShopHqCategory attribute={label}>
       {attribute === "Brand" ?
         <RefinementList 
                 attribute={attribute}
                 limit={10}
                showMore={true}
                 facetOrdering
                 defaultRefinement={defaultBrand?[defaultBrand]:[]}
                 searchable={isSearchable}
                 showMoreLimit={100} />
                 :
                 <RefinementList 
                 attribute={attribute}
                 limit={10}
                 showMore={true}
                 facetOrdering
                 searchable={isSearchable}
                 showMoreLimit={100} />
     }
     </ShopHqCategory>
     <hr className="line"></hr>
     </>
    // : items?.length ? 
    // <>
    // <ShopHqCategory attribute={label}>
    //   {attribute === "Brand" ?
    //     <RefinementList 
    //             attribute={attribute}
    //             limit={10}
    //             showMore={true}
    //             facetOrdering
    //             defaultRefinement={defaultBrand?[defaultBrand]:[]}
    //             searchable={isSearchable}
    //             showMoreLimit={100} />
    //             :
    //             <RefinementList 
    //             attribute={attribute}
    //             limit={10}
    //             showMore={true}
    //             facetOrdering
    //             searchable={isSearchable}
    //             showMoreLimit={100} />
    // }
    // </ShopHqCategory>
    // <hr className="line"></hr>
    // </>
    : <></>}
    </>
  )
} 
export default connectRefinementList(CustomRefinementList)