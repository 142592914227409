import {
  connectCurrentRefinements,
  // CurrentRefinements,
  // ClearRefinements,
} from "react-instantsearch-dom";
import { Link,useLocation } from "react-router-dom";
//  import CustomClearRefinements from "./ShopHqClearRefinements";
// import { capitalize } from 'instantsearch.js/es/lib/utils';
import { capitalizeFirstLetter } from "../../service/ShophqService";
import aa from "search-insights";

const CurrentRefinementss = ({ items, refine }: any) => {
  const location = useLocation();
  return items.length > 0 ? (
    <div className="">
      <div className="selection-div">
        <div className="search-filter-selection-lable productSearch-lbl-selection">
          Your Selections:
        </div>
        {/* <CustomClearRefinements clearsQuery /> */}
        <div className="cx-ais-ClearRefinements">
        <div className="cx-ais-ClearRefinements-button">
        <Link to={location?.pathname + location?.search}>Clear All</Link>
        </div>
        </div>
        {/* <ClearRefinements 
       transformItems={(items:any) => {
        return items//.filter((item:any) => item.attribute !== 'Categories.lvl0');
      }}             
          translations={{
            reset: "Clear all",
          }}
        /> */}
      </div>
      <div className="cx-CustomCurrentRefinementList-wrapper">
        {items
          ?.filter(
            (v:any, i:any, a:any) =>
              a.findIndex((v2:any) => v?.attribute === v2?.attribute) === i
          ).map((item: any, indexItem: any) => {
          let label = item?.items
          ? ""
          : typeof item?.currentRefinement === "string"
          ? item?.currentRefinement
          : item?.label;
        if (
          item?.currentRefinement?.min ||
          item?.currentRefinement?.max
        ) {
          const minVal = "$" + item?.currentRefinement?.min;
          const maxVal = "$" + item?.currentRefinement?.max;
          label = minVal + " - " + maxVal;
        }
          return item.items ? (
            item.items.map((nested: any, index: any) => (              
              Refinement(nested.label, nested.value, refine, index)
            ))
          ) : (
            Refinement(label, item.value, refine, indexItem)
          );
        })}
      </div>

       {/* <CurrentRefinements
        clearsQuery
        transformItems={(items: any[]) => {
          const filteredItems=items
          ?.filter(
            (v, i, a) =>
              a.findIndex((v2) => v?.attribute === v2?.attribute) === i
          )

          return filteredItems
            ?.filter(
              (v, i, a) =>
                a.findIndex((v2) => v?.attribute === v2?.attribute) === i
            )
            .map((item: any) => {
              let label = item?.items
                ? ""
                : typeof item?.currentRefinement === "string"
                ? item?.currentRefinement
                : item?.label;
              if (
                item?.currentRefinement?.min ||
                item?.currentRefinement?.max
              ) {
                const minVal = "$" + item?.currentRefinement?.min;
                const maxVal = "$" + item?.currentRefinement?.max;
                label = minVal + " - " + maxVal;
              }

              return {
                ...item,
                label: label,
              };
            });
        }}
      />  */}
      <hr className="line" />
    </div>
  ) : (
    <></>
  );
};

const Refinement = (name: any, nameValue: any, refine: any, index: any) => {
  aa('viewedFilters', {
    index: (window as any).config.allIndex,
    eventName: "PLP: Filter Viewed ",
    filters: [`filter:${name}`],
  });
  return (
    <div
      className="search-filter-label productSearch-lbl-selected-items"
      key={`rf${index}`}
      title={name}
      onClick={(event) => {
        event.preventDefault();
        document.getElementById(name)?.click();
        // aa('clickedFilters', {
        //   index: (window as any).config.allIndex,
        //   eventName: "PLP: Filter Removed ",
        //   filters: [`filter:${name}`],
        // });
        refine(nameValue);
      }}
    >
      {capitalizeFirstLetter(name)}
      {/* {JSON.stringify(name)} */}
      <i
        className="fa fa-close search-filter-icon"
        data-groupname="selected"
        data-order={index}
        data-encoded-refinename={name}
      ></i>
    </div>
  );
};

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinementss);

export default CustomCurrentRefinements;
